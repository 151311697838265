<template>
  <v-row style="font-size: medium; font-weight: bold;white-space:normal;color:black">
    <v-col cols="12" class="pb-0" :style="type == 'day' ? 'font-size:15px;white-space: nowrap;' : 'font-size:15px;white-space: nowrap;'">T: {{ tipo.find(t => t.val == event.tipo)?.title}} </v-col>
    <v-col cols="12" class="py-0" :style="type == 'day' ? 'font-size:15px;white-space: nowrap;' : 'font-size:15px;white-space: nowrap;'">E: {{ event.espacio.join(" + ") }}</v-col>
    <v-col cols="12" class="py-0" :style="type == 'day' ? 'font-size:15px;white-space: nowrap;' : 'font-size:15px;white-space: nowrap;'">H: {{ horario.filter(h => event.horario.includes(h.val)).map(h => h.siglas).join(" + ") }}</v-col>
    <v-col cols="12" class="py-0" :style="type == 'day' ? 'font-size:15px;white-space: nowrap;' : 'font-size:15px;white-space: nowrap;'">M: {{ medio.filter(h => event.medio.includes(h.val)).map(h => h.title).join(" + ") }}</v-col>
    <v-col cols="12" class="py-0" :style="type == 'day' ? 'font-size:15px;white-space: nowrap;' : 'font-size:15px;white-space: nowrap;'" v-if="event.tipo == 5">ALQ: {{ medio.filter(h => (event?.medioAlq || []).includes(h.val)).map(h => h.title).join(" + ") }}</v-col>
    <!-- <v-col cols="12" class="py-0" :style="type == 'day' ? 'font-size:15px;' : ''" v-if="type=='day'">EXP: {{ event.explicacion || '' }}</v-col> -->
    <v-col cols="12" class="py-0" :style="type == 'day' ? 'font-size:15px;white-space: nowrap;' : 'font-size:15px;white-space: nowrap;'" v-if="type != 'month' && event.tipo != 5">MAI: {{ equipoMai.filter(h => event.equipoMai.includes(h.idUnidadEquipo)).map(h => h.inicialesApellidos || h.nombre).join(" + ") }}</v-col>
    <v-col cols="12" class="py-0" :style="type == 'day' ? 'font-size:15px;white-space: nowrap;' : 'font-size:15px;white-space: nowrap;'" v-if="type != 'month' && event.tipo != 4">BDG: {{ equipoBdg.filter(h => event.equipoBdg.includes(h.idUnidadEquipo)).map(h => h.inicialesApellidos || h.nombre).join(" + ") }}</v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    event: Object,
    type: String,
    tipo: Array,
    horario: Array,
    medio: Array,
    equipoMai: Array,
    equipoBdg: Array,
  },
};
</script>

<style>
</style>